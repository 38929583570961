import React from "react";

export const Filters = (props) => {
  let { tags, filters, setFilters, setCurrentProjects } = props;

  tags = Array.from(tags)
    .sort()
    .map((tag, index) => (
      <Tag key={index} tag={tag} filters={filters} setFilters={setFilters} setCurrentProjects={setCurrentProjects} />
    ));
  return <div className="ml-2 ml-sm-4 mr-2 mr-sm-4 mt-5 pt-5 d-flex justify-content-end">{tags}</div>;
};

const Tag = (props) => {
  let { tag, filters, setFilters, setCurrentProjects } = props;

  const onClickHandler = () => {
    if (tag === filters) {
      setFilters(null);
      setCurrentProjects(null);
    } else {
      setFilters(tag);
      setCurrentProjects(tag);
    }
  };

  return (
    <div
      className={`m-1 ml-1 mr-1 ml-sm-3 mr-sm-3`}
      style={{ textDecoration: tag === filters ? "underline" : "none", cursor: "pointer" }}
      onClick={() => onClickHandler()}
    >
      <a className="link">{tag.toUpperCase()}</a>
    </div>
  );
};
