import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import slugify from "../utils/slugify";
import { ErrorBoundary } from "./error";

const Project = (props) => {
  let { project } = props;

  let { title, tags, urlPrefix = "project" } = project;

  let picture = project.titlePicture?.childImageSharp.gatsbyImageData;

  let linkedTags = tags.join(", ");

  let slug = `/${urlPrefix}/${slugify(title)}/`;

  return (
    <ErrorBoundary>
      <div className="col-sm-4 mt-5">
        <Link to={slug} className="link">
          <GatsbyImage image={picture} alt={title} className="circle-cursor" />
        </Link>
        <hr className="p-0 m-0 mt-1 line-color" />
        <Link to={slug} className="link">
          <div className="text-dark medium">{title}</div>
        </Link>
        <hr className="p-0 m-0 line-color" />
        <small style={{ color: "#B3B3B3", lineHeight: 1.9 }}>{linkedTags}</small>
        <hr className="p-0 m-0 line-color" />
      </div>
    </ErrorBoundary>
  );
};

export default Project;
