import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { graphql, useStaticQuery } from "gatsby";
import Project from "../components/project";
import PageLayout from "../components/pageLayout";
import { Filters } from "../components/filters";

const Projects = () => {
  const { allContent } = useStaticQuery(query);

  const content = allContent.edges.map((node) => node);

  const [filters, setFilters] = React.useState(null);

  const [projects, setProjects] = React.useState(null);

  const setCurrentProjects = (newFilters) => {
    let currentProjects = content.map((node, index) => (
      <Project key={index} project={node.node}/>
    ));

    if (newFilters) {
      currentProjects = content
        .filter((node) => newFilters === node.node.tags[0])
        .map((node, index) => <Project key={index} project={node.node}/>);
    }

    setProjects(currentProjects);
  };

  useEffect(() => {
    setCurrentProjects(null);
  }, []);

  let tags = new Set(allContent.edges.map((node) => node.node.tags[0]).flat());

  return (
    <PageLayout title={"Work"} onShop={false}>
      <Filters tags={tags} filters={filters} setFilters={setFilters} setCurrentProjects={setCurrentProjects} />
      <div className={`row ml-2 ml-sm-4 mr-2 mt-0 mr-sm-4`}>{projects}</div>
      <br />
    </PageLayout>
  );
};

export default Projects;

const query = graphql`
  {
    allContent(
      sort: { fields: added, order: DESC }
      filter: { hidden: { ne: true }, parent: { internal: { description: { regex: "/content/" } } } }
    ) {
      edges {
        node {
          title
          tags
          titlePicture {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;
